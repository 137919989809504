@import "~react-image-gallery/styles/css/image-gallery.css";

.autografika-slider {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.vise-button button {
  background-color: #335dac;
  border: none;
  margin-bottom: 20px;
}

ul {
  list-style-type:none;
}

.display-text-mobile {
  display: none;
}

.autografika-main {
  padding-top: 20px;

  font-family: "Montserrat", sans-serif;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  background-color: white;
}

.autografika-main h1 {
  font-weight: bold;
  margin-top: 100px;
  color: #335dac;
  text-align: left;
  text-transform: uppercase;
}

.autografika-main h4 {
  color: #335dac;
  text-align: left;
  text-transform: uppercase;
  font-weight: bold;
}

.autografika-main p {
  font-weight: bold;
  text-align: left;
}

.usluge-dugme {
  display: none;
}

@media (max-width: 648px) {
  .autografika-main h1 {
    font-weight: bold;

    margin-top: 40px;
    color: #335dac;
    padding-left: 20px;
    text-align: left;
  }

  .autografika-main h4 {
    padding-left: 20px;
  }

  .display-text-mobile {
    display: block;
  }

  .vise-button {
    display: none;
  }

  .autografika-main {
    padding-top: 0px;
  }

  .autografika-main p {
    text-align: left;
    padding: 20px;
    color: black;
  }

  .autografika-slider {
    margin-top: 0px;
  }

  .reverse {
    flex-direction: column-reverse;
  }
}
