.nav-main {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px
}
.header {
  display: flex;
  background: white;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  height: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  z-index: 99;
  position: relative;
}

.usluge-nav {
  display: none;
  text-transform: uppercase;
}

.nase-usluge {
  cursor: pointer;
  position: relative;
  width: 100%;
  max-width: 100%;

  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.dropdown-toggle {
 border: none;
 background: none;
 text-decoration: none;
    color: #335dac;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}





.usluge-dropdown {
  display: none;
  position: absolute;
 margin-top: 20px;
  padding-left: 0px;
  width: 100%;
  max-width: 100%;
  background: rgb(255, 255, 255);
 
 
}





.nase-usluge:hover .usluge-dropdown {
  display: block;

}

.logo-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-container img {
  width: 120px;
  height: auto;
}
.logo {
  width: 45px;
  height: 45px;
}
.nav-options {
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 20px;
  list-style-type: none;
  margin-bottom: 0px;
}

.nav-options a {
  text-decoration: none;
  color: #335dac;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.nav-options a:hover {
  color: black;
}

.mobile-option {
  display: none;
}

.option:hover {
  color: #335dac;
}
.signin-up {
  display: flex;
  padding: 0px 5px;
  list-style-type: none;
}
.sign-in {
  padding-right: 50px;
  align-self: center;
}
.sign-in :hover {
  color: white;
}
.signup-btn {
  padding: 10px 10px;
  height: 2.4rem;
  border-radius: 3px;
  background: rgb(222, 9, 241);
  color: white;
  cursor: pointer;
  align-items: center;
}
.mobile-menu {
  display: none;
}

@media (max-width: 850px) {
  .nav-main {
    padding-left: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 648px) {
  .header {
    padding: 0px 10px;
    width: 100%;
  }


  .nav-main {
    width: 100%;
  }

  .logo {
    width: 45px;
    height: 45px;
  }
  .nav-options {
    display: flex;
    width: 100%;
    height: auto;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }

  .nav-options.active {
    background: rgb(255, 255, 255);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    height: auto;
    padding-left: 0px;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10vw;
    padding: 30px 0px;
  }
  .sign-up {
    background: rgb(222, 9, 241);
    border-radius: 3px;
    color: white;
    padding: 20px 0px;
    width: 80%;
    align-self: center;
    border-radius: 3px;
  }

  .signin-up {
    display: none;
  }
}
.mobile-menu {
  display: block;
}
