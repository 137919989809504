@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

.each-fade img {
  max-width: 100%;
  height: auto;
  margin-top: 50px;
  border-radius: 10px;
  border: 2px solid #45beaa;
}
.graphic {
  background-color: black;
}

.graphicimage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15%;
  padding-top: 100px;
  margin-bottom: 10px;
}

.graphic-text {
  text-align: center;
  font-family: "Montserrat", sans-serif;
}

.graphic-text > div {
  background-color: black;
  margin: 40px;
  padding: 20px;
  font-size: 30px;
  border-radius: 25px;
  transition: transform 0.2s; /* Animation */
}

.graphic-text > div:hover {
  transform: scale(
    1.1
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
