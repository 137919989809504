@import url("https://fonts.googleapis.com/css2?family=Preahvihear&display=swap");

.pocetna {
  width: 100%;
  height: auto;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
 
}


.col-md-8 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}



.pocetna-pozadina {
  background-position: 50% 0;
  background-repeat: repeat;
  background-attachment: fixed;
}

.specijal {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px; 
  text-align: center;
  height: auto;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 50px;
  margin-top: 50px;
}

.specijal h3,
.specijal h5 {
  width: 100%; 
  color: #335dac;
}

.specijal button {
  width: 160px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background-color: #335dac;
  
}



.broj-mobile {
  background-color: black;
  color: white;
  text-align: center;
  padding-top: 10px;
  display: none;
  font-family: "Montserrat", sans-serif;
 
}



.specijal button:hover {
  background-color: black;
  border: none;
}

.colors {
  text-align: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.responsive-img {
  max-width: 100%;
  height: 40vh;
  width: 100%;
}

.image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff; /* Text color */
}

.image-text h1 {
  margin: 0;
  font-size: 150px;
  letter-spacing: 20px;
}

.image-text span {
  color: black;
}

.image-text h2 {
  color: black;
  font-size: 70px;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 10px;
}

@media only screen and (max-width: 1000px) {
  .pocetna {
    padding-left: 0;
    padding-right: 0;
  }


  .specijal {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px; /* Adjust the gap between columns as needed */
    text-align: center;
    height: auto;
    margin-bottom: 50px;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-family: "Montserrat", sans-serif;
  }
  
  .specijal h3,
  .specijal h5 {
    width: 100%; /* Make each item take full width of its container */
    text-transform: uppercase;
  }
  
  .specijal button {
    width: 160px;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    
  }
}

@media only screen and (max-width: 508px) {
  .card {
    width: 100%;
  }

  [data-aos] {
    opacity: 1 !important;
    transform: none !important;
    transition: none !important;
  }

  .pocetna {
    width: 100%;
    max-width: 100%;
    padding-top: 0px;
  }

  .broj-mobile {
    display: flex;
  justify-content: space-evenly;
  }
}
