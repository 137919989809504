.about-us {
  width: 90%;
  height: auto;
  margin-top: 200px;
  margin-bottom: 200px;
  font-family: "Montserrat", sans-serif;
  margin-left: auto;
  font-weight: bold;
  margin-right: auto;

}

.vise-o-nama a {
  background-color: #335dac;
  padding: 15px 25px;
  color: white;
  margin-left: auto;
  margin-right: auto;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-radius: 5px;
}

.vise-o-nama a:hover {
  background-color: black;
 
}

.button-container {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}



.about-us h1 {
  text-align: center;
  color: #335dac;
}

.about-us p {
  text-align: left;
  margin: 50px;
  font-size: larger;
}

.two-column-wrapper {
  display: flex;
  max-width: 100%; 
  margin: 0 auto;
}

.about-us-column {
  flex: 1;
  padding: 20px;
  background-color: white; 
}

.image-column {
  flex: 1;
}

.responsive-image {
  width: 100%;
  height: auto;
  margin-top: 100px;
}

.naslov-o-nama-mobitel {
  display: none;
}

@media (max-width: 1000px) {
  .two-column-wrapper {
    flex-direction: column-reverse; 
  }

  .about-us-column,
  .image-column {
    flex: none;
    width: 100%; 
  }
}

@media (max-width: 648px) {
  .about-us {
    width: 100%;
    max-width: 100%;
    letter-spacing: 0.5px;
    color: black;
    
  }

  .responsive-image {
    margin-top: 0px;
  }

  .vise-o-nama a {
    padding: 10px 15px;
  }

  .about-us p {
    margin: 0px;
    padding: 15px;
    font-size: medium;
    text-align: left;
  }

  .about-us h1 {
    color: #335dac;
  }

  .naslov-o-nama {
    display: none;
  }

  .naslov-o-nama-mobitel {
    display: block;
  }

  .about-us-column {
    padding: 1px;
  }


}
