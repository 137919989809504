@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.counter-main {
  background-color: #335dac;
  color: #fff;
  font-family: "Roboto Mono", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
 
}

.counter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 30px 50px;
}

.counter {
  font-size: 60px;

  margin-top: 10px;
}

.counter-main-mobile {
  display: none;
  background-color: #335dac;
  color: #fff;
  font-family: "Roboto Mono", sans-serif;

  align-items: center;
  justify-content: center;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
}


@media only screen and (max-width: 672px) {
  .counter-main {
    flex-direction: column;
    display: none;
  }
  .counter-main-mobile {
    display: block;
    border-radius: 0px;
  }
}

@media only screen and (max-width: 520px) {
  .counter-main {
    flex-direction: column;
    display: none;
  }
  .counter-main-mobile {
    display: block;
    border-radius: 0px 300px 00px 0px;
  }
}
