.our-main {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  height: auto;
}

.our-cover {
  background-color: black;
  width: 100%;
}

.our-main h1 {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #335dac;
  padding-top: 50px;
}

@media (max-width: 768px) {
  .our-main {
    width: 100%;
    max-width: 100%;
    margin-bottom: 100px;
  }
}
