.stirodur-grid {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  height: auto;
  column-gap: 0px;
  
}

.stirodur-grid-mobile {
  display: none;
}

@media (max-width: 520px) {
  .stirodur-grid-mobile {
    column-width: 160px;
    column-gap: 0px;
    display: block;
  }

  .stirodur-grid {
    display: none;
  }
}
