.contact {
  width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  
}

.containercontact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  height: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  
}


.left-column {
  flex: 1;
  padding: 10px;
 text-align: center;
 margin-top: auto;
 margin-bottom: auto;
 font-family: "Montserrat", sans-serif;
 font-weight: bold;

}

.socials-contact a{
  margin: 10px;
}

.left-column h3 {
  text-transform: uppercase;
 
    margin-top: 100px;
    color: #335dac;
}

.left-column h4 {
  text-transform: uppercase;
}

.right-column {
  flex: 1;
  padding: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 850px) {
  .containercontact {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    height: auto;
    margin-top: 40px;
  margin-bottom: 40px;

  }

  .contact {
    height: auto;
  }

  .left-column,
  .right-column {
    flex: 1;
    margin-left: auto;
    margin-right: auto;
  }
}