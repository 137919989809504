$logo-max-width: 120px;
$logo-max-height: 60px;

.logogrid {
    display: grid;
    
    
    grid-auto-rows: $logo-max-height * 1.25;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img {
        object-fit: cover;
        max-width: $logo-max-width;
        max-height: $logo-max-height;
        filter: grayscale(100%) contrast(25%);
        transition: filter 0.3s ease;

        &:hover {
            filter: grayscale(0) contrast(100%);
        }
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
        &__img {
            object-fit: cover;
            max-width: $logo-max-width;
            max-height: $logo-max-height;
            filter: grayscale(100%) contrast(25%);
            transition: filter 0.3s ease;
    
            &:hover {
                filter: grayscale(0) contrast(100%);
            }
        }
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        padding-left: 0 !important;
       
    }
}

.graphic-logo {
    display: flex;
    flex-direction: column;
    justify-items: center;
   
}

.logogrid__img {
    text-align: center;
}
