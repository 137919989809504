/* PKatalog.css */

@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Montserrat&family=Poppins:wght@300;400;500;600&family=Rajdhani:wght@600&family=Raleway:wght@500&display=swap");

.graphic-products {
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 100px;
}

.spliter img {
  width: 100%;
}

.category-list {
  padding: 0;

  
}



.category-list ul {
  list-style-type: none;
  padding-left: 0px;
}

.category-list li {
  margin-bottom: 10px;
}

.category-list a {
  font-size: 19px;
  text-decoration: none;
  line-height: 20px;
  color: #335dac;
  padding-top: 4px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-weight: bold;
  padding-left: 2px;
}


.category-list img {
  width: 20px;
  height: 20px;
  margin-left: auto;
  margin-right: auto;
}

.category-list a:hover {
  color: black;
}

.category-list h5 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #335dac;
  font-size: 21px;
  line-height: 1.095em;
  letter-spacing: 0.03em;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.offers-cards {
  margin-top: 100px;
}

.row {
  padding-bottom: 50px;
  padding-left: 0px;
  padding-right: 0px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 100%;
  margin: 0 auto;
}

.card {
  width: 32%;
  height: auto !important;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border-width: 0px !important;
}




.card h3 {
  padding-left: 10px;
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #335dac;
}

.card:hover .read-more-btn {
  opacity: 1;
}

.card p {
  font-family: "Montserrat", sans-serif;
  text-align: left;
}

.card img {
  max-width: 100%;
  width: 300px;
  height: 200px;
  display: block;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  transition: filter 0.3s ease; /* Add transition property for smooth effect */
  cursor: pointer;
  filter: grayscale(100%); /* Set initial grayscale to make the images gray */
}

.card:hover img {
  filter: grayscale(0%); /* On hover, remove grayscale effect to display the original color */
}





@media only screen and (max-width: 1000px) {
  .card {
    width: 48%;
  }
}

@media only screen and (max-width: 806px) {
  .graphic-products {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media only screen and (max-width: 780px) {
  .graphic-products {
    padding-left: 60px;
  }
}




@media only screen and (max-width: 520px) {
  .card {
    width: 100%;
   
    margin-bottom: 0px;
  }

  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 508px;
    max-height: 174px;
    display: block;
    margin: 0;
    filter: brightness(60%);
    
  }



  
 
  .graphic-products {
    padding-left: 0px;
    padding-right: 0px;
  }

  .category-list ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    list-style: none;
  }

  .category-list {
    margin-top: 50px;
  }

  .row {
    flex-direction: column;
    text-align: center;
  }

  .card h3 {
    position: absolute;
    top: 75%;
    left: 45%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    width: 100%;
    color: white;
    z-index: 29;
  }

  .card p {
    position: absolute;
    bottom: 0px;
    left: 22px;
    color: white;
  }

  .category-list li {
    margin: 20px;
    display: flex;
    flex-direction: column;
  }

  .category-list img {
    width: 22px;
    height: 22px;
    margin-left: auto;
    margin-right: auto;
  }

  .category-list a {
    font-size: 16px;
    color: black;
    font-weight: bold;
  }

  .category-list h5 {
    color: black;
  }

  .spliter {
    display: none;
  }
}
