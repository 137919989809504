.more-about-us-main h1 {
    color: #335dac;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    margin-top: 100px;

}



.more-about-us-main {
    background-color: #fff;
    font-family: Montserrat,sans-serif;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    width: 100%
}

.more-about-us-main p {
    font-weight: 700;
    text-align: left;
}


@media (max-width: 648px) {
    .more-about-us-main h1 {
        color: #335dac;
    font-weight: 700;
    margin-top: 40px;
    padding-left: 20px;
    text-align: left;
    }

    .more-about-us-main p {
        color: #000;
    padding: 20px;
    text-align: left;
    }
}